(function(window) {
  
  var menubar = document.createElement('div');
  menubar.style.position = 'fixed';
  menubar.style.top = '0';
  menubar.style.width = '100%';
  menubar.style.zIndex = 9999;
  menubar.style.textAlign = 'center';
  menubar.style.color = 'rgba(255, 255, 255, 1.0)';
  menubar.style.backgroundColor = 'rgba(251, 140, 0, 0.9)';
  menubar.style.padding = '0.5em';

  menubar.innerHTML = 'You are in preview Mode: ';

  var exit = document.createElement('a');
  exit.innerHTML = 'exit';
  exit.href = document.location.href;
  exit.search = '?exitPreview';
  menubar.appendChild(exit);

  var internalpreview = {
    'show': function() {
      document.getElementsByTagName('body')[0].appendChild(menubar);
    }
  };

  window.internalpreview = internalpreview;
  internalpreview.show();
}(window));
